import React, { useState } from 'react'
import { Modal } from 'semantic-ui-react'
import { usePrismic } from '../../context/PrismicContext'
import { useAuthContext } from '../../context/AuthContext'
import { StyledInput, Container, BorderlessButton, ThemedIcon } from '..'

const EditDisplayNameModal = ({ open, onClose, displayName }) => {
  const { qUser, updateQUser, handleRefreshSite } = useAuthContext()
  const {
    prismicData: {
      prismicProfilePage: { display_name },
      prismicGeneral: { btn_save, btn_cancel },
    },
  } = usePrismic()

  const [value, setValue] = useState(displayName)
  const [loading, setLoading] = useState(false)

  const onSave = async () => {
    setLoading(true)
    const data = { ...qUser, displayName: value }
    await updateQUser(data)
    setLoading(false)
    onClose()
  }

  return (
    <Modal
      closeIcon
      style={{ width: '80%', maxWidth: 300 }}
      open={open}
      centered={true}
      closeOnDimmerClick={true}
      closeOnEscape
      onClose={onClose}
    >
      <Modal.Content style={{ marginTop: 24 }}>
        <StyledInput
          value={value}
          onChange={e => setValue(e.target.value)}
          placeholder={display_name}
        />
        <Container height="1.25rem">
          {loading && <ThemedIcon loading size="large" name="circle notched" />}
        </Container>
        <Container justify="flex-end" align="center">
          <BorderlessButton disabled={loading} onClick={onClose}>
            {btn_cancel.toUpperCase()}
          </BorderlessButton>
          <BorderlessButton disabled={loading} onClick={onSave}>
            {btn_save.toUpperCase()}
          </BorderlessButton>
        </Container>
      </Modal.Content>
    </Modal>
  )
}

export default EditDisplayNameModal
