import React, { useEffect, useState, useRef } from 'react'
import { navigate } from 'gatsby'
import { Button, Popup } from 'semantic-ui-react'
import { Checkbox } from 'semantic-ui-react'
import { QService } from '../services/q-services'
// Components
import {
  Loading,
  PageHeader,
  Seo,
  Primary as PrimaryButton,
  StyledInput,
} from '../components'
// Context
import { useAuthContext } from '../context/AuthContext'
import { usePrismic } from '../context/PrismicContext'
import { useCartContext } from '../context/CartContext'
// Styles
import { Container, ProfileContainer, Wrapper } from '../styles/common'
import { StyledSelect } from '../components/common'
import {
  AssociateItem,
  AssociateType,
  ContactInfoItem,
  ContactInfoItemImg,
  ContactInfoItemValue,
  ProfileImgContainer,
  ProfileImg,
  UserName,
  StyledIcon,
} from '../styles/Profile.styles'
import infoIcon from '../svg/InfoIcon.svg'
import editIcon from '../svg/Edit Icon.svg'
import { ThemedPopup } from '../components/Verification/Verification.styles'
import BecomeAmbButton from '../components/Buttons/BecomeAmbButton'
import EditDisplayNameModal from '../components/EditDisplayName/EditDisplayNameModal'
// Servies & Utils
import { Magento } from '../services/magento'

const ProfilePage = () => {
  const [showCopied, setShowCopied] = useState(false)
  const [open, setOpen] = useState(false)
  const [commNewEnroll, setCommNewEnroll] = useState(false)
  const popupRef = useRef()
  const [openDisplayNamePopup, setOpenDisplayNamePopup] = useState(false)
  const [isEditDisplayNameOpen, setIsEditDisplayNameOpen] = useState(false)
  const [showButton, setShowButton] = useState(false)
  const [countryData, setCountryData] = useState(null)
  const { qUser, isAuthenticated, userType } = useAuthContext()
  const {
    prismicData: {
      prismicGeneral,
      prismicMarketAndLanguage,
      prismicAddUpdateShippingPage: {
        address_1,
        address_2,
        city,
        state,
        zip_code,
      },
      prismicProfilePage: {
        communication_type_icon,
        default_image,
        email_icon,
        email,
        loading,
        new_enrollment_email,
        new_user,
        notification_preferences,
        page_title,
        phone_number_icon,
        profile_header,
        text,
        unavailable,
        update,
        username,
        display_name_popup,
      },
    },
  } = usePrismic()
  const { isAmbOfferInCart } = useCartContext()

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/')
    } else {
      showCommPrefs()
      getAddress()
    }
  }, [isAuthenticated])

  const profileImgUrl = () => {
    if (qUser?.profileUrl) return qUser.profileUrl

    return default_image.url
  }

  const showCommTypes = () => {
    if (qUser.receiveEmail && qUser.receiveText)
      return `${email[0].text}/${text[0].text}`
    if (qUser.receiveEmail && !qUser.receiveText) return `${email[0].text}`
    return `${text[0].text}`
  }

  const showCommPrefs = async () => {
    const {
      associateCommunicationPreferences: { enableNewDownlineAssociateEmail },
    } = await QService.User.communicationPreferences(null)
    setCommNewEnroll(enableNewDownlineAssociateEmail)
  }

  const updateCommPrefs = async () => {
    setCommNewEnroll(!commNewEnroll)
  }

  const setCommPrefs = async () => {
    await QService.User.communicationPreferences({
      enableNewDownlineAssociateEmail: commNewEnroll,
    })
  }

  const copyToClipboard = str => {
    if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
      setShowCopied(true)
      return navigator.clipboard.writeText(str)
    }
    return Promise.reject('The Clipboard API is not available.')
  }

  const checkIsDefault = treeNodeFor => {
    if (
      treeNodeFor?.treeNodeFor?.associate?.address?.address1 ===
        '365 S Garden Grove Ln' &&
      treeNodeFor?.treeNodeFor?.associate?.address?.address2 === 'STE 200' &&
      treeNodeFor?.treeNodeFor?.associate?.address?.city === 'Pleasant Grove'
    ) {
      return true
    }
    return false
  }

  const getAddress = async () => {
    if (!qUser?.legacyAssociateId) return
    await QService.User.getAssociateAddress({
      legacyAssociateId: qUser?.legacyAssociateId,
    }).then(treeNodeFor => {
      let defaultAddr = checkIsDefault(treeNodeFor)
      setNewAddress({
        address1: defaultAddr
          ? ''
          : treeNodeFor?.treeNodeFor?.associate?.address?.address1,
        address2: defaultAddr
          ? ''
          : treeNodeFor?.treeNodeFor?.associate?.address?.address2,
        city: defaultAddr
          ? ''
          : treeNodeFor?.treeNodeFor?.associate?.address?.city,
        state: defaultAddr
          ? ''
          : treeNodeFor?.treeNodeFor?.associate?.address?.state,
        zip: defaultAddr
          ? ''
          : treeNodeFor?.treeNodeFor?.associate?.address?.zip,
        countryCode: defaultAddr
          ? ''
          : treeNodeFor?.treeNodeFor?.associate?.address?.countryCode.toUpperCase(),
      })
    })
  }

  const [newAddress, setNewAddress] = useState({
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    countryCode: '',
  })

  const updateAssociateAddress = async () => {
    const qsObj = {
      associateId: qUser.associateId,
      address: newAddress,
    }
    await QService.User.updateAssociate(qsObj).then(res => {
      if (res) {
        setShowButton(false)
      }
    })
  }

  const handleClick = event => {
    setShowButton(true)
  }

  const handleInputChange = event => {
    const { name, value } = event.target
    setNewAddress({ ...newAddress, [name]: value })
  }

  useEffect(() => {
    if (!newAddress?.countryCode) return
    ;(() =>
      Magento.Store.getCountryData({
        id: newAddress.countryCode,
      }).then(({ country }) => {
        setCountryData(country)
      }))()
  }, [newAddress.countryCode])

  const { markets } = prismicMarketAndLanguage

  if (!qUser) return <Loading loading={true} message={loading[0].text} />

  return (
    <>
      <Seo title={page_title[0].text} />
      <Wrapper style={{ maxWidth: '800px' }}>
        <PageHeader exitRoute="/">{profile_header[0].text}</PageHeader>
        <Container align="center" padded>
          <ProfileImgContainer />
          <ProfileImg data-qa="profileImage" src={profileImgUrl()} />
        </Container>

        <Container align="center" padded>
          <UserName data-qa="profileUsername">
            {`${qUser.firstName} ${qUser.lastName}` || new_user[0].text}
          </UserName>
          {qUser?.associateType === 'AMBASSADOR' && qUser.displayName && (
            <Container row justify="space-between">
              <div style={{ flex: 1 }} />
              <Container row justify="center" style={{ flex: 3 }}>
                <UserName data-qa="profileUsername">
                  {qUser.displayName || ''}
                </UserName>
              </Container>
              <div style={{ flex: 1 }}>
                <ThemedPopup
                  content={display_name_popup}
                  on="click"
                  onClose={() => setOpenDisplayNamePopup(false)}
                  onOpen={() => setOpenDisplayNamePopup(true)}
                  open={openDisplayNamePopup}
                  position="bottom right"
                  hideOnScroll={true}
                  trigger={
                    <StyledIcon style={{ marginLeft: 8 }} src={infoIcon} />
                  }
                />
                <StyledIcon
                  onClick={() => setIsEditDisplayNameOpen(true)}
                  src={editIcon}
                  style={{ marginLeft: 8 }}
                />
              </div>
            </Container>
          )}
          <AssociateType>
            <AssociateItem bold data-qa="profileAssociateType">
              {qUser?.associateType}
            </AssociateItem>
            <AssociateItem bold data-qa="profileAssociateID">
              &nbsp;|&nbsp;{qUser?.legacyAssociateId}
            </AssociateItem>
          </AssociateType>

          {qUser?.associateType === 'AMBASSADOR' ? (
            <>
              <AssociateType>
                <AssociateItem slug data-qa="profileAssociateSlug">
                  {`qsciences.com/store/${qUser?.associateSlug}`}
                  <Popup
                    content={prismicGeneral.msg_copied}
                    eventsEnabled={showCopied}
                    on="click"
                    onClose={() => setOpen(false)}
                    onOpen={() => setOpen(true)}
                    hideOnScroll
                    onMount={() => setTimeout(() => setOpen(false), 2000)}
                    open={open}
                    trigger={
                      <Button
                        className="copy-button"
                        basic
                        compact
                        icon="clipboard"
                        ref={popupRef}
                        onClick={() =>
                          copyToClipboard(
                            `qsciences.com/store/${qUser?.associateSlug}`
                          )
                        }
                      />
                    }
                  />
                </AssociateItem>
              </AssociateType>
            </>
          ) : null}
        </Container>
        <ProfileContainer>
          <Container align="center" padding="2em 1em 1em 1em">
            <ContactInfoItem>
              <ContactInfoItemImg src={phone_number_icon.url} />
              <ContactInfoItemValue data-qa="profilePhoneNumber">
                {qUser.primaryPhoneNumber || unavailable[0].text}
              </ContactInfoItemValue>
            </ContactInfoItem>

            <ContactInfoItem>
              <ContactInfoItemImg src={email_icon.url} />
              <ContactInfoItemValue data-qa="profileEmail">
                {qUser?.uniqueEmailAddress ?? unavailable[0].text}
              </ContactInfoItemValue>
            </ContactInfoItem>
            <ContactInfoItem>
              <ContactInfoItemImg src={communication_type_icon.url} />
              <ContactInfoItemValue data-qa="profileCommunication">
                {showCommTypes()}
              </ContactInfoItemValue>
            </ContactInfoItem>
            <ContactInfoItem>
              <ContactInfoItemImg src={communication_type_icon.url} />
              <ContactInfoItemValue>
                <span>{notification_preferences[0].text}</span>
              </ContactInfoItemValue>
            </ContactInfoItem>
            <ContactInfoItem>
              <Checkbox
                style={{
                  padding: '0px 0px 0px 10px',
                  width: '65%',
                }}
                name="communication_newEnroller"
                value="communication_newEnroller"
                label={{
                  children: (
                    <Container>
                      <span>{new_enrollment_email[0].text}</span>
                    </Container>
                  ),
                }}
                checked={commNewEnroll}
                onChange={() => updateCommPrefs()}
              />
              <Container style={{ width: '75%' }}>
                <PrimaryButton
                  style={{ width: '70%', margin: '0px' }}
                  type="submit"
                  onClick={() => setCommPrefs()}
                >
                  <span>{update[0].text}</span>
                </PrimaryButton>
              </Container>
            </ContactInfoItem>
            {userType !== 'AMBASSADOR' && !isAmbOfferInCart && (
              <ContactInfoItem>
                <BecomeAmbButton $insidemenu={false} />
              </ContactInfoItem>
            )}
          </Container>
          <Container padding="2em 1em 1em 1em">
            <AssociateItem bold>Address</AssociateItem>
            <Container maxWidth="100%">
              {newAddress.address1 ? (
                <StyledInput
                  defaultValue={newAddress.address1}
                  name="address1"
                  onClick={handleClick}
                  onChange={handleInputChange}
                  placeholder={address_1[0].text}
                />
              ) : (
                <StyledInput
                  defaultValue={newAddress.address1}
                  name="address1"
                  onClick={handleClick}
                  onChange={handleInputChange}
                  placeholder={address_1[0].text}
                />
              )}
              {newAddress.address2 ? (
                <StyledInput
                  defaultValue={newAddress.address2}
                  name="address2"
                  onClick={handleClick}
                  onChange={handleInputChange}
                  placeholder={address_2[0].text}
                />
              ) : (
                <StyledInput
                  defaultValue={newAddress.address2}
                  name="address2"
                  onClick={handleClick}
                  onChange={handleInputChange}
                  placeholder={address_2[0].text}
                />
              )}
              {newAddress.city ? (
                <StyledInput
                  defaultValue={newAddress.city}
                  name="city"
                  onClick={handleClick}
                  onChange={handleInputChange}
                  placeholder={city[0].text}
                />
              ) : (
                <StyledInput
                  defaultValue={newAddress.city}
                  name="city"
                  onClick={handleClick}
                  onChange={handleInputChange}
                  placeholder={city[0].text}
                />
              )}
            </Container>
            <Container
              maxWidth="100%"
              justify="space-between"
              style={{ flexDirection: 'row' }}
            >
              {newAddress.state ? (
                <StyledSelect
                  name="state"
                  value={newAddress.state}
                  onClick={handleClick}
                  onChange={handleInputChange}
                >
                  <option value="">{newAddress.state}</option>

                  {countryData &&
                    countryData.available_regions.map(({ id, name }) => (
                      <option key={id} value={name}>
                        {name}
                      </option>
                    ))}
                </StyledSelect>
              ) : (
                <StyledSelect
                  name="state"
                  value={newAddress.state}
                  onClick={handleClick}
                  onChange={handleInputChange}
                >
                  <option value="">
                    {newAddress.state ? newAddress.state : state[0].text}
                  </option>

                  {countryData &&
                    countryData.available_regions.map(({ id, name }) => (
                      <option key={id} value={name}>
                        {name}
                      </option>
                    ))}
                </StyledSelect>
              )}
              {newAddress.zip ? (
                <StyledInput
                  width={'50%'}
                  style={{ marginLeft: '5px' }}
                  defaultValue={newAddress.zip}
                  name="zip"
                  onClick={handleClick}
                  onChange={handleInputChange}
                  placeholder={zip_code[0].text}
                />
              ) : (
                <StyledInput
                  width={'50%'}
                  style={{ marginLeft: '5px' }}
                  defaultValue={newAddress.zip}
                  name="zip"
                  onClick={handleClick}
                  onChange={handleInputChange}
                  placeholder={zip_code[0].text}
                />
              )}
            </Container>
            <Container style={{ width: '100%', alignItems: 'flex-end' }}>
              {newAddress.countryCode ? (
                <StyledSelect
                  name="countryCode"
                  value={newAddress.countryCode}
                  onClick={handleClick}
                  onChange={handleInputChange}
                >
                  {markets.map(item => (
                    <option key={item.country_code} value={item.country_code}>
                      {item.label}
                    </option>
                  ))}
                </StyledSelect>
              ) : (
                <StyledSelect
                  name="countryCode"
                  value={newAddress.countryCode}
                  onClick={handleClick}
                  onChange={handleInputChange}
                >
                  {markets.map(item => (
                    <option key={item.country_code} value={item.country_code}>
                      {item.label}
                    </option>
                  ))}
                </StyledSelect>
              )}
              {showButton ? (
                <PrimaryButton
                  style={{ width: '70%', margin: '0px' }}
                  type="submit"
                  onClick={() => updateAssociateAddress()}
                >
                  <span>{update[0].text}</span>
                </PrimaryButton>
              ) : null}
            </Container>
          </Container>
        </ProfileContainer>
      </Wrapper>
      {isEditDisplayNameOpen && (
        <EditDisplayNameModal
          open={isEditDisplayNameOpen}
          onClose={() => setIsEditDisplayNameOpen(false)}
          displayName={qUser?.displayName}
        />
      )}
    </>
  )
}

export default ProfilePage
