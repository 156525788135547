import styled, { css } from 'styled-components'
import { Image } from 'semantic-ui-react'
import { colors } from './GlobalStyle'

export const ProfileImgContainer = styled.div`
  width: 200px;
  height: 200px;
  border-radius: 100%;
  background-color: ${colors.platinum};
  display: flex;
  justify-content: center;
  align-items: center;
  filter: blur(10px);
`

export const ProfileImg = styled(Image)`
  width: 72px;
  height: 72px;
  border-radius: 100%;
  position: absolute;
  bottom: 136px;
  left: 0px;
`

export const UserName = styled.div`
  font-size: 1.25em;
  font-weight: 700;
  padding-bottom: 10px;
`

interface AssociateTypeProps {
  bold: boolean
}

export const AssociateType = styled.div<Partial<AssociateTypeProps>>`
  display: flex;
  margin-bottom: 5px;
  font-weight: ${props => (props.bold ? '500' : '400')};

  &:last-child {
    margin-bottom: 0;
  }
`

interface AssociateItemProps {
  bold: boolean
  slug: boolean
}

export const AssociateItem = styled.div<Partial<AssociateItemProps>>`
  font-size: 1em;

  ${props =>
    props.bold &&
    css`
      font-weight: bold;
    `}

  ${props =>
    props.slug &&
    css`
      position: relative;
      font-weight: bold;
      color: ${props.theme.colors.metallicSeaweed};

      .copy-button {
        position: absolute;
        top: -10px;
        right: -50px;
      }
    `}
`

export const ContactInfoItem = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  width: 100%;
  padding: 0 10px;

  @media ${({ theme }) => theme.device.sm} {
    width: 500px;
    margin-top: 20px;
  }
`

export const ContactInfoItemImg = styled(Image)`
  width: 37px;
  height: 37px;
  margin-right: 10px;
`

export const ContactInfoItemValue = styled.span`
  font-size: 1em;
`

export const StyledIcon = styled.img`
  height: 24px;
  width: 24px;
  cursor: pointer;
`
